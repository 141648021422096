/*
 * @Author       : suzhen
 * @Email        : suzhen@dianzhong.com
 * @Date         : 2022-09-19 11:42:43
 * @LastEditors  : your name
 * @LastEditTime : 2022-12-26 14:42:11
 * @Description  : 文件简介
 */
import { getUserId, getStorage,getSessionStorage } from '@/utils/cookie.js'
import axios from "axios";
import router from '@/router/index.js'

let log_idx = 1
let host = location.host

// 发版版本号
export const version = '2.4.4'

// 获取机型 上行header与打点对机型的参数不一致
export function os () {
  var u = navigator.userAgent
  var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  var IsAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
  if (IsAndroid) {
    return 0
  } else if (isIos) {
    return 1
  } else {
    return 0
  }
}

// 生成随机数
export function randomStr (num) {
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let length = num || 16
  let result = ''
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

// 随机字符串
export function dateFormatTime(value) {
  var date = new Date(value);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  var h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;
  second = second < 10 ? ('0' + second) : second;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second; //年月日时分秒
  // return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
}

// 获取当前域名的appId
export function getAppId() {
  let id = location.host.split('.')[0];
  if(!id.startsWith('wx')) {
    let store = getStorage('readQuery', true) || {}
    id = store.appid || ''
  }
  return id
}

export const getQueryString = (search) => {
  if(search) {
    let q = search.substring(1)
    q = q.split('&')
    if(q.length > 0) {
       let query = q.reduce((c,v) =>{
        let q1 = v.split('=');
        if(q1.length == 2) {
          c[q1[0]] = q1[1]
          return c
        }
      },{})
      return query
    }
  }
  return ''
}

//打点函数
export function h5Log(key1, key2, action, params) {
  let setQuery = getStorage('readQuery', true)
  if (!Object.keys(setQuery).length) {
    setQuery = localStorage.getItem('readQuery') || ''
  }
  try {
      let data = {
          log_idx: log_idx++,
          ctime: dateFormatTime(Date.parse(new Date())),
          ip: '', // ip地址
          openid: getStorage('hoid') || '', // 小程序openID
          unionid: '' || '', // 小程序unionid
          brand: '', // 手机品牌
          model: '', // 机型
          uid: getUserId() || '', // uid
          vn: version, // 版本号
          apn: getSessionStorage('network') || 'none', // 	wifi，4G
          chid: setQuery.channelId || '', // 渠道号
          pkna: getAppId(), // com.xx.xx
          app_name: 'wx-h5-video',
          app: location.href,
          vip: setQuery.vip || '',//0/1,NO/YES
          ptx: os() ? 1 : 2, //2 安卓 1 IOS
          sessionid: getStorage('sessionid'), //每次启动用户的随机sessionId 20位数字字符
          key1: key1,
          key2: key2,
          action: action,
          data: params, // 其他参数
          ext: {
              // query:getApp().globalData.query, 启动参数
              rid: setQuery.referral_id || '', // 来源id
              utdid: getStorage('utdid'),
              query: setQuery
          } // 扩展字段
      };

      if(process.env.NODE_ENV === 'production' && host.indexOf('xnzkj') > -1){
        axios({
          method: 'GET',
          url: 'https://log.kyread.cn/jinyi_log.php',
          dataType: 'json',
          params: {
            json: JSON.stringify(data)
          }
        })
      }else {
        console.log('打点参数', data)
      }
  } catch (e) {
    console.log(e)
  }
}
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export function formatTime (data) {
  let date = new Date(data * 1000)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
export function timeToMinute(times){
  let t='';
  if(times > -1){
    // let hour = Math.floor(times/3600);
    let min = Math.floor(times/60) % 60;
    let sec = times % 60;
  //   if(hour < 10) {
  //     t = '0'+ hour + ":";
  //   } else {
  //     t = hour + ":";
  //   }
    if(min < 10){t += "0";}
    t += min + ":";
    if(sec < 10){t += "0";}
    t += sec.toFixed(2);
  }
  t=t.substring(0,t.length-3);
  return t;
}
export function myParseFloat(num){
  return parseFloat(num)?parseFloat(num):num;
}
export function sliceString(str){
  var r = parseFloat(str)
  var result
  if(r){
    r += ''
    result = str.slice(r.length)
    return result;
  }else{
    return '';
  }
}
export function getNewUrl(name) {
  var loca = window.location;
  var baseUrl = loca.origin + loca.pathname + "?";
  var query = loca.search.substr(1)
  if (query.indexOf(name) > -1) {
    var obj = {}
    var arr = query.split("&")
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split("=")
      obj[arr[i][0]] = arr[i][1]
    }
    delete obj[name]
    var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&")
    return url
  }
}

export function payInfoLog(){
  let query = router.currentRoute.value.query
  let queryCBtime = query.cBtime
  if(queryCBtime){
    let cBtimeStorage = window.localStorage.getItem('cBtime')||''
    cBtimeStorage = cBtimeStorage?JSON.parse(cBtimeStorage):{}
    if(!cBtimeStorage[queryCBtime]){
      console.log('链接支付取消打点')
      h5Log('orderRust', 'recharge', 'event', { ...query,payInfoCancel:1})
      cBtimeStorage[queryCBtime] = 1;
      window.localStorage.setItem('cBtime',JSON.stringify(cBtimeStorage))
    }
  }else{
    window.localStorage.getItem('cBtime') && window.localStorage.removeItem('cBtime')
  }
}
